<template>
  <div class="bgStyle wrapper">
    <div class="head">
      <a-button type="primary" class="back" @click="toBack"> 返回 </a-button>
    </div>
    <a-row type="flex" :gutter="16">
      <a-col flex="406px" style="width: 406px; font-size: 13px">
        <div class="top">
          <span>基本信息</span>
        </div>
        <a-row class="l-row">
          <a-col :span="23"
            >文档名称:
            <span>{{ objDetail.docName }}</span>
          </a-col>
        </a-row>
        <a-row class="l-row">
          <a-col :span="23"
            >文档编码:
            <span>{{ objDetail.docCode }}</span>
          </a-col>
        </a-row>
        <a-row class="l-row">
          <a-col :span="12"
            >图文档版本:
            <span>{{ objDetail.docVersion }}</span>
          </a-col>
          <a-col :span="12"
            >文档类型:
            <span>{{ filterType(objDetail.docType) }}</span>
          </a-col>
        </a-row>
        <a-row class="l-row">
          <a-col :span="12">大小: {{ objDetail.docSize }}KB</a-col>
          <a-col :span="12">文档格式: {{ objDetail.docFormat }}</a-col>
        </a-row>
        <a-row class="l-row">
          <a-col :span="12">上传人: {{ objDetail.docUploader }}</a-col>
          <a-col :span="12"
            >上传时间: {{ objDetail.creationTime | filterDate }}</a-col
          >
        </a-row>
        <a-row class="l-row">
          <a-col :span="12"
            >最后修改人: {{ objDetail.docLastModifierName }}</a-col
          >
          <a-col :span="12"
            >修改时间:
            {{ objDetail.docLastModificationTime | filterDate }}</a-col
          >
        </a-row>
      </a-col>
      <a-col flex="1" class="con">
        <a-form-model :model="form" layout="inline" style="margin-bottom: 16px">
          <a-form-model-item>
            <a-input
              v-model="form.operOrPhone"
              style="width: 180px"
              placeholder="操作人/手机号"
              allowClear
            ></a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              v-model="form.isMyCompany"
              style="width: 130px"
              placeholder="是否本公司"
              allowClear
            >
              <a-select-option value="true"> 是 </a-select-option>
              <a-select-option value="false"> 否 </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              v-model="form.operType"
              style="width: 130px"
              placeholder="选择操作类型"
              allowClear
            >
              <a-select-option
                v-for="item in arrOperTypes"
                :value="item.id"
                :key="item.id"
              >
                {{ item.codeName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-range-picker
              v-model="form.time"
              style="width: 260px"
              format="YYYY-MM-DD"
            />
          </a-form-model-item>

          <a-form-model-item>
            <a-button type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-form-model>

        <a-table
          :columns="columns"
          :data-source="data"
          :rowKey="(record) => record.id"
          bordered
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template slot="operateType" slot-scope="text">
            {{ filterOperType(text) }}
          </template>
          <template slot="creationTime" slot-scope="text">
            {{ text | filterDate }}
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  getFigureDocOperateLogs,
  getAllOperateTypes,
  getById,
} from "@/api/figureDocument.js";

const columns = [
  {
    title: "操作人",
    dataIndex: "operatorName",
  },
  {
    title: "手机号",
    dataIndex: "operatorPhoneNum",
  },
  {
    title: "所属公司",
    dataIndex: "companyName",
  },
  {
    title: "操作类型",
    dataIndex: "operateType",
    scopedSlots: { customRender: "operateType" },
  },
  {
    title: "操作时间",
    dataIndex: "creationTime",
    scopedSlots: { customRender: "creationTime" },
  },
];

export default {
  components: {},
  props: {
    id: { type: String, default: () => "" },
    edit: { type: [Boolean, String], default: () => false },
    isFrom: { type: String, default: () => "list" },
    figureDocumentId: { type: String, default: () => "" },
  },
  data() {
    return {
      form: {
        operOrPhone: "",
        isMyCompany: undefined,
        operType: undefined,
        time: [],
      },

      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
      data: [],
      columns,

      arrOperTypes: [],
      objOperType: {},

      objDetail: {},
      objDocType: {},
    };
  },
  filters: {
    filterDate(val) {
      if (val) {
        let dt = val.substring(0, 19).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
  },
  created() {
    this.getOperType();
  },
  methods: {
    toBack() {
      // let figureDocumentId = this.figureDocumentId;
      // let id = this.id;
      // let edit = this.edit;
      // let isFrom = this.isFrom;

      // if(isFrom == 'list') {
      //   this.$router.push({
      //     name: "FigureDocManageDetail",
      //     params: {
      //       figureDocumentId,
      //       edit,
      //       isFrom
      //     },
      //   });
      // } else {
      //   this.$router.push({
      //     name: "FigureDocManageDetailHis",
      //     params: {
      //       figureDocumentId,
      //       id,
      //       isFrom
      //     }
      //   });
      // }
      this.$router.go(-1);
    },
    getOperType() {
      getAllOperateTypes().then((res) => {
        console.log("oootype", res);
        this.arrOperTypes = res || [];
        this.getObjOperType(res);

        this.objDocType = JSON.parse(localStorage.getItem("objDocType"));
        this.getDetail();

        this.getData();
      });
    },
    getObjOperType(arr) {
      let _this = this;
      arr.forEach((item) => {
        _this.objOperType[item.id] = item.codeName;
      });
      console.log("objOperType", this.objOperType);
      localStorage.setItem("objOperType", JSON.stringify(this.objOperType));
    },
    filterOperType(val) {
      return this.objOperType[val] || "";
    },
    filterType(val) {
      return this.objDocType[val] || "";
    },
    getData() {
      let pageSize = this.pagination.pageSize;
      let skipCount = (this.pagination.current - 1) * pageSize;
      let figureDocumentId = this.figureDocumentId; // '5a61dd9f-a9b0-7a9e-6cb8-39fe08651eb2'
      let key = this.form.operOrPhone;
      let isOurCompany = this.form.isMyCompany;
      let operateType = this.form.operType;
      let beginTime =
        this.form.time[0] && this.form.time[0].format("YYYY-MM-DD 00:00:00");
      let endTime =
        this.form.time[1] && this.form.time[1].format("YYYY-MM-DD 23:59:59");

      let params = {
        skipCount,
        maxResultCount: pageSize,
        figureDocumentId,
        key,
        isOurCompany,
        operateType,
        beginTime,
        endTime,
      };

      getFigureDocOperateLogs(params).then((res) => {
        console.log("getList", res);
        this.data = res.items;
        this.pagination.total = res.totalCount;
      });
    },
    getDetail() {
      let figureDocumentId = this.figureDocumentId;
      getById(figureDocumentId).then((res) => {
        console.log("getDetail", res);

        this.objDetail = res || {};
      });
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getData();
    },
    search() {
      this.pagination.current = 1;
      console.log(this.form);
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #fff !important;
}

.head {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 0 2px 6px;
  span {
    font-size: 22px;
    font-weight: bold;
    i {
      cursor: pointer;
    }
  }
}

.l-row {
  line-height: 66px;
  > .ant-col {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
